.writing-block {
    cursor: pointer;
    transition: background-color 300ms;
    padding: 1rem 1rem 1rem 0; /* Remove left padding */
    border-radius: 0.5rem;
  }
  
  .writing-title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: inline-block;
    position: relative;
  }
  
  .writing-title::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: currentColor;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .writing-block:hover .writing-title::after {
    transform: scaleX(1);
    transform-origin: bottom left;
    background-color: #002fa7;
  }
  
  .writing-excerpt {
    color: rgba(75, 85, 99, 1); /* Tailwind's gray-600 */
    margin-bottom: 0.5rem;
  }

  .custom-article-font {
    font-size: 1.1rem; /* Adjust the size as needed */
  }
  
  .writing-date {
    font-size: 0.875rem;
    color: rgba(107, 114, 128, 1); /* Tailwind's gray-500 */
  }

  .nav-link {
    position: relative;
    display: inline-block;
    text-decoration: none; /* Remove default underline */
  }

  .nav-link::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -2px; /* Adjust as needed */
    left: 0;
    background-color: currentColor;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .nav-link:hover::after,
  .nav-link.underline::after {
    transform: scaleX(1);
    transform-origin: bottom left;
    background-color: #002fa7;
  }

.project-card {
  transition: transform 0.3s ease-in-out;
  border: none; /* Remove border */
  box-shadow: none; /* Remove shadow */
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-card img { /* Ensure the image container is fully filled */
  object-fit: auto; /* Crop the image if necessary to fill the container */
}

.project-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: inline-block;
  position: relative;
}

.project-title::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.project-card:hover .project-title::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  background-color: #002fa7;
}

.custom-video {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto; /* Center the video */
}

.small-text {
  font-size: 1.1rem; /* This makes the text smaller */
}

.contact-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; /* Adjust this value to reduce or increase the space between email and x */
}
