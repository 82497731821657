@font-face {
  font-family: 'RomanSerif';
  src: url('/public/fonts/romanserif/RomanSerif.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'RomanSerif', serif;
  background-color: #fcfcfc;
}
.transition-wrapper {
  position: relative;
  width: 100%;
}

.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.page-content {
  position: relative;
  width: 100%;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.markdown > * {
  all: revert;
}

.writing-block:hover .writing-title {
  text-decoration-color: #002fa7;
}